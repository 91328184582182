// import store from "@/state/store";

export default [
  
  {
    // Home
    path: "/",
    name: "home",
    component: () => import("../views/pages/home"),
    meta: { title: "Trang chủ"},
  },

  {
    // Content
    path: "/content",
    name: "content",
    meta: { title: "Content"},
    component: () => import("../views/pages/content"),
  },



  // Pages
  {
    // starter
    path: "/starter",
    name: "starter",
    meta: { title: "Starter"},
    component: () => import("../views/pages/starter"),
  },
    

  // Giới thiệu

  {
    // Giới thiệu
    path: "/gioi-thieu.html",
    name: "gioi-thieu",
    meta: { title: "Giới thiệu"},
    component: () => import("../views/aboutus/aboutus-landing"),
  },
  
  {
    // Aboutus Pages
    path: "/gioi-thieu/:aboutus_pages",
    name: "aboutus-pages",
    meta: { title: "Giới thiệu"},
    component: () => import("../views/aboutus/aboutus-pages"),
  },

  {
    // Giá trị cốt lỗi New
    path: "/gioi-thieu/gia-tri-cot-loi.html",
    name: "gia-tri-cot-loi",
    meta: { title: "Giá trị cốt lỗi"},
    component: () => import("../views/aboutus/giaTriCotLoi"),
  },

  {
   // Đội ngũ lảnh đạo
   path: "/gioi-thieu/doi-ngu-lanh-dao.html",
   name: "doi-ngu-lanh-dao",
   meta: { title: "Đội ngũ lảnh đạo"},
   component: () => import("../views/aboutus/doiNguLanhDao"),
 },
  
  {
    // Công ty thành viên
    path: "/gioi-thieu/cong-ty-thanh-vien.html",
    name: "cong-ty-thanh-vien",
    meta: { title: "Công ty thành viên"},
    component: () => import("../views/aboutus/congTyThanhVien"),
  },

  {
    // Lịch sử
    path: "/gioi-thieu/lich-su.html",
    name: "lich-su",
    meta: { title: "Lịch sử"},
    component: () => import("../views/aboutus/history"),
  },

  {
    // Trách nhiệm xã hội
    path: "/gioi-thieu/trach-nhiem-xa-hoi.html",
    name: "trach-nhiem-xa-hoi",
    meta: { title: "Trách nhiệm xã hội"},
    component: () => import("../views/aboutus/trachNhiemXaHoi"),
  },


  // Ecosystem
  {
    // Ecosystem
    path: `/he-sinh-thai.html`,
    name: "he-sinh-thai",
    meta: { title: "Lĩnh vực hoạt động"},
    component: () => import("../views/ecosystem/ecosystem-landing"),
  },
  
  {
    // Ecosystem Pages
    path: "/he-sinh-thai/:ecosystem_pages",
    name: "ecosystem-pages",
    meta: { title: "Giới thiệu"},
    component: () => import("../views/ecosystem/ecosystem-pages"),
  },



  // blog
  {
    // blog
    path: "/blog.html",
    name: "blog",
    meta: { title: "Blog"},
    component: () => import("../views/news/news-landing"),
  },
  {
    // Danh mục blog
    path: "/blog/:category_slug",
    name: "doanh-muc-blog",
    meta: { title: "Danh mục blog"},
    component: () => import("../views/news/news-list"),
  },
  {
    // Chi tiết blog
    path: "/blog/:category_slug/:slug",
    name: "chi-tiet",
    meta: { title: "Chi tiết"},
    component: () => import("../views/news/news-detail"),
  },


  // Tuyển dụng
  {
    path: "/tuyen-dung.html",
    name: "tuyen-dung",
    meta: { title: "Tuyển dụng"},
    component: () => import("../views/pages/recruitment.vue"),
  },




  // Liên hệ
  {
    path: "/lien-he.html",
    name: "lien-he",
    meta: { title: "Liên hệ"},
    component: () => import("../views/pages/contact.vue"),
  },

];